import {createContext, useContext, useCallback, useEffect, useRef, useState, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Project from '../store/ducks/project.duck'
import * as Precon from '../store/ducks/precon.duck'
import * as Reference from '../store/ducks/reference.duck'
import {HubConnectionBuilder} from '@microsoft/signalr'
import isEmpty from 'lodash/isEmpty'
import {COMPLETE} from '../pages/home/setup/BCSyncButton'
import {COMPLETE as RECALC_COMPLETE} from '../components/Recalc'
// import {useGridEditing} from '../context/GridEditingContext'

const APIMessagesContext = createContext({
  hasReceivedUpdateTradeSummary: false,
  updateTradeSummaryMessageHandled: () => {},
  hasReceivedUpdatePreconResources: false,
  updatePreconResourcesMessageHandled: () => {},
  hasReceivedUpdatePreconDept: false,
  updatePreconDeptMessageHandled: () => {},
  hasReceivedUpdateExecutiveSummary: false,
  updateExecutiveSummaryMessageHandled: () => {},
  hasReceivedUpdateBidTradeGrid: false,
  updateBidTradeGridMessageHandled: () => {}
})

export const useAPIMessages = () => {
  const apiMessagesContext = useContext(APIMessagesContext)
  if (!apiMessagesContext) {
    throw new Error('Error')
  }
  return apiMessagesContext
}

const APIMessagesContextProvider = ({children}) => {
  const [isConnected, setIsConnected] = useState(false)

  // Component to receive messages via SignalR from the API when a recalculation has been completed and fetch fresh data as required
  // Messages will contain ProjectID, BidQuoteID and BidTradeID. These are then compared to state, so as to only fetch data for project/quote that the user has selected and loaded (i.e. all users receive all messages)

  const dispatch = useDispatch()
  const authUserID = useSelector((state) => state.auth.userID)
  const connection = useSelector((state) => state.project.hubConnection)
  const filters = useSelector((state) => state.auth.Filters)
  const assumedUserID = useSelector((state) => state.auth.assumedUserID)

  // apiUserID: 5: authUserID:undefined, apiProjectID: 7229, apiBidQuoteID: 0, apiBidTradeID: 0

  /* Editing State */
  // const {bidSheetIsEditing, preconResourceSheetIsEditing, preconDeptSheetIsEditing, bidSummaryGridIsEditing, bidSummaryTotalGridIsEditing, bidTradeGridIsEditing} = useGridEditing()

  // Precon
  const [hasReceivedUpdatePreconResources, setHasReceivedUpdatePreconResources] = useState(false)
  const updatePreconResourcesMessageHandled = () => {
    setHasReceivedUpdatePreconResources(false)
  }
  const [hasReceivedUpdatePreconDept, setHasReceivedUpdatePreconDept] = useState(false)
  const updatePreconDeptMessageHandled = () => {
    setHasReceivedUpdatePreconDept(false)
  }

  // Executive Summary
  const [hasReceivedUpdateExecutiveSummary, setHasReceivedUpdateExecutiveSummary] = useState(false)
  const updateExecutiveSummaryMessageHandled = () => {
    setHasReceivedUpdateExecutiveSummary(false)
  }

  // Bid Trade Grid
  const [hasReceivedUpdateBidTradeGrid, setHasReceivedUpdateBidTradeGrid] = useState(false)
  const updateBidTradeGridMessageHandled = () => {
    setHasReceivedUpdateBidTradeGrid(false)
  }

  // Bid Sheet
  const [hasReceivedUpdateTradeSummary, setHasReceivedUpdateTradeSummary] = useState(false)
  const updateTradeSummaryMessageHandled = useCallback(() => {
    setHasReceivedUpdateTradeSummary(false)
    dispatch(Precon.actions.clearBidSheetGridTemp())
  }, [dispatch])

  // Refs to track current keys for user - signalR goes out to all users, so only refresh when it matches the project/bidQuote the user has loaded
  const timeRef = useRef(Date.now())

  const updatePrecon = useCallback(
    (apiProjectID, resources = false, expenses = false) => {
      dispatch(Project.actions.fetchPrecon({projectID: apiProjectID, popCache: true}))
      if (resources) {
        // if (preconResourceSheetIsEditing) {
        // setHasReceivedUpdatePreconResources(true)
        // dispatch(Project.actions.fetchPreconResourceGridTemp({projectID: apiProjectID, popCache: true}))
        // } else {
        dispatch(Project.actions.fetchPreconResourceGrid({projectID: apiProjectID, popCache: true}))
        // }
      }
      if (expenses) {
        // if (preconDeptSheetIsEditing.current) {
        // setHasReceivedUpdatePreconDept(true)
        // dispatch(Project.actions.fetchPreconExpensesGridTemp({projectID: apiProjectID, popCache: true}))
        // } else {
        dispatch(Project.actions.fetchPreconExpensesGrid({projectID: apiProjectID, popCache: true}))
        // }
      }
      dispatch(Project.actions.clearSummary())
      dispatch(Project.actions.setAPIMessages('Preconstruction totals updated...'))
    },
    [dispatch]
  )

  const updateExecutiveSummary = useCallback(
    (apiProjectID, apiBidQuoteID) => {
      dispatch(Project.actions.clearSummary())
      // if (bidSummaryGridIsEditing) {
      //   setHasReceivedUpdateExecutiveSummary(true)
      //   dispatch(Precon.actions.fetchBidTradesTemp({bidQuoteID: apiBidQuoteID, popCache: true}))
      // } else {
      dispatch(Precon.actions.fetchBidTradeSummaryGrid({bidQuoteID: apiBidQuoteID, projectID: apiProjectID, popCache: true}))
      // }
      // if (bidSummaryTotalGridIsEditing) {
      // setHasReceivedUpdateExecutiveSummary(true)
      // dispatch(Precon.actions.fetchBidSummaryGridTotalsTemp({bidQuoteID: apiBidQuoteID, popCache: true}))
      // } else {
      // }
      dispatch(Reference.actions.setCache({caller: {type: 'FETCH_CSICodes'}, clear: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADES'}, clear: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADE'}, clear: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADEGRID'}, clear: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_TRADEALTERNATESGRID'}, clear: true}))
      dispatch(Precon.actions.fetchBidAlternatesGrid({bidQuoteID: apiBidQuoteID}))
      dispatch(Project.actions.setAPIMessages('Executive Summary updated...'))
    },
    [dispatch]
  )

  const updateTradeSummary = useCallback(
    (apiProjectID, apiBidQuoteID) => {
      dispatch(Precon.actions.fetchBidTradeSummaryGrid({bidQuoteID: apiBidQuoteID, apiProjectID: apiProjectID, popCache: true}))
      dispatch(Precon.actions.fetchBidQuote({bidQuoteID: apiBidQuoteID, popCache: true}))
      // if (bidSheetIsEditing) {
      // setHasReceivedUpdateTradeSummary(true)
      // dispatch(Precon.actions.fetchBidSheetGridTemp({bidQuoteID: apiBidQuoteID, popCache: true}))
      // dispatch(Precon.actions.fetchBidSummaryGridTotalsTemp({bidQuoteID: apiBidQuoteID, popCache: true}))
      // } else {
      dispatch(Precon.actions.fetchBidSheetGrid({bidQuoteID: apiBidQuoteID, apiProjectID: apiProjectID, popCache: true}))
      // }
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADE'}, clear: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADEGRID'}, clear: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADEQUOTE'}, clear: true}))
      dispatch(Project.actions.setAPIMessages('Trade Summary updated...'))
    },
    [dispatch]
  )

  const updateProjectSummary = useCallback(
    (apiProjectID, apiBidQuoteID) => {
      dispatch(Project.actions.fetchProjectHeader({projectID: apiProjectID, popCache: true}))
      dispatch(Project.actions.fetchSummary({projectID: apiProjectID, popCache: true}))
      // if (!bidSheetIsEditing) {
      dispatch(Precon.actions.fetchBidTradeSummaryGrid({bidQuoteID: apiBidQuoteID, projectID: apiProjectID, popCache: true}))
      dispatch(Precon.actions.fetchBidSheetGrid({bidQuoteID: apiBidQuoteID, projectID: apiProjectID, popCache: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDSUMMARY_GRIDTOTALS'}, clear: true}))
      // }
      dispatch(Project.actions.setAPIMessages('Project totals updated...'))
    },
    [dispatch]
  )

  // The useCallback method on parseMessage causes the connection to fail silently, so I removed it (Aug 15 2023)
  const parseMessage = (userID, apiProjectID, apiBidQuoteID, apiBidTradeID, message, calledByPayload) => {
    console.log(` API message: *********** ${message} ***********`)
    console.log(`apiUserID: ${userID}: authUserID:${authUserID}, apiProjectID: ${apiProjectID}, apiBidQuoteID: ${apiBidQuoteID}, apiBidTradeID: ${apiBidTradeID}, calledByPayload: ${calledByPayload}`)

    // convert calledByPayload from arrray to object
    let calledByIds = calledByPayload ? JSON.parse(calledByPayload).reduce((obj, item) => Object.assign(obj, {[item.name]: item.value}), {}) : {}

    const bidTradeGridRowRefresh = (callerBidQuoteID, callerBidTradeID) => {
      dispatch(Precon.actions.fetchBidQuote({bidQuoteID: callerBidQuoteID, popCache: true}))
      dispatch(Precon.actions.fetchBidTrade({bidTradeID: callerBidTradeID, popCache: true}))
      dispatch(Precon.actions.fetchBidTradeGrid({bidTradeID: callerBidTradeID, popCache: true}))
      // dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADEGRID'}, clear: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADESUMMARYGRID'}, clear: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}))
      dispatch(Project.actions.clearSummary())
      dispatch(Project.actions.setAPIMessages('Trade Scope updated...'))
    }

    const bidTradeGridRowRefreshUpdateOnly = (callerBidQuoteID, callerBidTradeID) => {
      dispatch(Precon.actions.fetchBidQuote({bidQuoteID: callerBidQuoteID, popCache: true}))
      dispatch(Precon.actions.fetchBidTrade({bidTradeID: callerBidTradeID, popCache: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADESUMMARYGRID'}, clear: true}))
      dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}))
      dispatch(Project.actions.clearSummary())
      dispatch(Project.actions.setAPIMessages('Trade Totals updated...'))
    }

    // Messages for user, with no other context
    if (authUserID === userID) {
      switch (message) {
        case 'Projects List':
          dispatch(Project.actions.fetchProjectsGrid({popCache: true}))
          dispatch(Reference.actions.fetchUserRecentProjects())
          break
        case 'Test Scripts':
          dispatch(Reference.actions.fetchTestScriptsGrid())
          break
        case 'Test Sections':
          dispatch(Reference.actions.fetchTestSectionsGrid())
          break
        case 'Test Executions':
          dispatch(Reference.actions.fetchTestExecutionGrid())
          break
        default:
          dispatch(Project.actions.setAPIMessages(message))
          break
      }
    }

    // For messages from a specific caller, which may be reference, i.e. have no bidquoteID or projectID
    if (authUserID === userID && !isEmpty(calledByIds)) {
      switch (message) {
        case 'Bid Quote Checklist':
          dispatch(Reference.actions.fetchBidQuoteChecklistTemplate({bidQuoteChecklistID: calledByIds.bidQuoteChecklistID, popCache: true}))
          break
        case 'Bid Trade Template':
          dispatch(Reference.actions.fetchBidTradeTemplate(calledByIds.bidTradeTemplateID))
          break
        case 'Clarification Template':
          dispatch(Reference.actions.fetchClarificationTemplate(calledByIds.clarificationTemplateID))
          break
        case 'Test Section':
          dispatch(Reference.actions.fetchTestSection({testSectionID: calledByIds.testSectionID}))
          break
        case 'Test Script':
          dispatch(Reference.actions.fetchTestScript({testScriptID: calledByIds.testScriptID}))
          break
        case 'Help Video File':
          dispatch(Reference.actions.fetchHelpVideos({popCache: true}))
          break
        case 'Bid Trade Quote':
          dispatch(Precon.actions.fetchBidTradeQuote({bidTradeQuoteID: calledByIds.bidTradeQuoteID, popCache: true}))
          break
        case 'Alternate':
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADESUMMARYGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDALTERNATESGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_TRADEALTERNATESGRID'}, clear: true}))
          dispatch(
            Precon.actions.fetchAlternate({
              bidQuoteID: apiBidQuoteID,
              alternateID: calledByIds.alternateId,
              popCache: true
            })
          )
          break
        case 'Allowance':
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDALLOWANCESGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_TRADEALLOWANCESGRID'}, clear: true}))
          dispatch(
            Precon.actions.fetchAllowance({
              bidQuoteID: apiBidQuoteID,
              allowanceID: calledByIds.allowanceID,
              popCache: true
            })
          )
          break
        case 'UnitPrice':
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDUNITPRICESGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_TRADEUNITPRICESGRID'}, clear: true}))
          dispatch(
            Precon.actions.fetchUnitPrice({
              bidQuoteID: apiBidQuoteID,
              unitPriceID: calledByIds.unitPriceID,
              popCache: true
            })
          )
          break
        case 'BC Integration Status':
          dispatch(Reference.actions.setRefStatus({statusName: 'syncBCProjectStatus', statusValue: calledByIds.status}))
          if (calledByIds.status === COMPLETE) {
            dispatch(
              Project.actions.fetchProjectsGrid({
                status: filters.projectsGridStatus,
                assumedUserID: assumedUserID
              })
            )
          }
          break
        case 'Test Execution Steps':
          dispatch(Reference.actions.fetchTestExecutionSteps({testScriptExecutionID: calledByIds.testScriptExecutionID}))
          break
        default:
          dispatch(Project.actions.setAPIMessages(message))
          break
      }
    }

    // Trade Specific messages
    if (authUserID === userID && apiBidTradeID > 0) {
      switch (message) {
        // case 'Trade Grid Row':
        //   let ts1 = Date.now();
        //   timeRef.current = ts1;
        //   callDelayedBidTradeGridRow(apiBidQuoteID, apiBidTradeID, ts1);
        //   break;
        case 'Bid Trade Grid Row Reorder':
          dispatch(Precon.actions.fetchBidTradeGrid({bidTradeID: apiBidTradeID, popCache: true}))
          break
        case 'Trade Grid Row':
          bidTradeGridRowRefresh(apiBidQuoteID, apiBidTradeID, timeRef.current)
          break
        case 'Bid Trade Grid Row Update Only':
          bidTradeGridRowRefreshUpdateOnly(apiBidQuoteID, apiBidTradeID, timeRef.current)
          break
        case 'Trade Budget Grid Row':
          dispatch(Precon.actions.fetchBidTrade({bidTradeID: apiBidTradeID, popCache: true}))
          break
        case 'Trade Budget Grid Row Insert':
          dispatch(Precon.actions.fetchBidTradeBudgetGrid({bidTradeID: apiBidTradeID, popCache: true}))
          dispatch(Precon.actions.fetchBidTrade({bidTradeID: apiBidTradeID, popCache: true}))
          break
        case 'No Refresh':
          dispatch(Precon.actions.fetchBidTradeTemp({bidTradeID: apiBidTradeID, popCache: true}))
          dispatch(Precon.actions.fetchBidTradeGridTemp({bidTradeID: apiBidTradeID, popCache: true}))
          dispatch(Precon.actions.fetchBidTradeBudgetGridTemp({bidTradeID: apiBidTradeID, popCache: true}))
          break
        case 'Alternates':
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDALTERNATESGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_TRADEALTERNATESGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADESUMMARYGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}))
          break
        case 'BidTradeQuote Posted':
          dispatch(Precon.actions.fetchBidTrade({bidTradeID: apiBidTradeID.toString(), popCache: true}))
          break
        case 'Reorder BidTradeQuotes':
          dispatch(Precon.actions.fetchBidTrade({bidTradeID: apiBidTradeID, popCache: true}))
          dispatch(Precon.actions.fetchBidTradeGrid({bidTradeID: apiBidTradeID, popCache: true}))
          break
        default:
          dispatch(Project.actions.setAPIMessages(message))
          break
      }
    }

    // BidQuote Specific messages
    if (authUserID === userID && apiBidQuoteID > 0) {
      switch (message) {
        case 'Bid Quote Setup':
          dispatch(Precon.actions.fetchBidQuoteAreas({bidQuoteID: apiBidQuoteID}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADEGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADESUMMARYGRID'}, clear: true}))
          break
        case 'Bid Trade Restored':
        case 'Executive Summary':
          updateExecutiveSummary(apiProjectID, apiBidQuoteID)
          break
        case 'Trade Summary':
          updateTradeSummary(apiProjectID, apiBidQuoteID)
          break
        case 'Bid Clarification':
          dispatch(Precon.actions.fetchBidClarifications({bidQuoteID: apiBidQuoteID, popCache: true}))
          break
        case 'Alternates':
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDALTERNATESGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_TRADEALTERNATESGRID'}, clear: true}))
          break
        case 'Allowances':
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDALLOWANCESGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_TRADEALLOWANCESGRID'}, clear: true}))
          break
        case 'UnitPrices':
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDUNITPRICESGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_TRADEUNITPRICESGRID'}, clear: true}))
          break
        case 'BidTradeQuote Posted':
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADEQUOTE'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}))
          // dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADEGRID'}, clear: true}))
          // dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDQUOTE'}, clear: true}))
          // dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADE'}, clear: true}))
          break
        case 'BC Integration Status':
          dispatch(Reference.actions.setRefStatus({statusName: 'syncBCProjectStatus', statusValue: calledByIds.status}))
          updateExecutiveSummary(apiProjectID, apiBidQuoteID)
          updateTradeSummary(apiProjectID, apiBidQuoteID)
          break
        default:
          ;(!apiBidTradeID || apiBidTradeID === 0 || apiBidTradeID === '') && dispatch(Project.actions.setAPIMessages(message))
          break
      }
    }

    // Project Specific messages
    if (authUserID === userID && apiProjectID > 0) {
      // GCs and Fee Project-specific messages
      switch (message) {
        case 'Project Setup':
        case 'Summary':
        case 'Pricing Review Summary':
          dispatch(Reference.actions.setRefStatus({statusName: 'recalcStatus', statusValue: RECALC_COMPLETE}))
          updateProjectSummary(apiProjectID, apiBidQuoteID)
          break
        case 'Bid Quote Setup':
        case 'Trade Summary':
          dispatch(Project.actions.clearSummary({projectID: apiProjectID, popCache: true}))
          break
        case 'Project Setup - Dates':
          dispatch(Project.actions.fetchManpower({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.fetchManpowerGrid({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.fetchProjectWeeks({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.fetchProjectMonths({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.clearSummary())
          dispatch(Project.actions.clearExpenses())
          dispatch(Project.actions.setAPIMessages('Project totals updated...'))
          break
        case 'Project Team Schedule':
        case 'Project Team Cost':
        case 'PerDiem Resource':
          dispatch(Project.actions.fetchManpower({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.fetchManpowerGrid({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.fetchManpowerCostGrid({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.clearSummary())
          dispatch(Project.actions.setAPIMessages('Project team totals updated...'))
          break
        case 'PutResourceNote':
          dispatch(Project.actions.fetchManpowerGrid({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.fetchManpowerCostGrid({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.setAPIMessages('Project team member note saved...'))
          break
        case 'Field Office Support':
          dispatch(Project.actions.fetchFieldOfficeSupport({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.fetchFieldOfficeSupportExpensesGrid({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.clearSummary())
          dispatch(Project.actions.setAPIMessages('Expense totals updated...'))
          break
        case 'General Requirements':
          dispatch(Project.actions.fetchGeneralRequirements({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.fetchGeneralRequirementsExpensesGrid({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.clearSummary())
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADES'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDSHEETGRID'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADE'}, clear: true}))
          dispatch(Precon.actions.setCache({caller: {type: 'FETCH_BIDTRADESUMMARYGRID'}, clear: true}))
          dispatch(Project.actions.setAPIMessages('Expense totals updated...'))
          break
        case 'Precon Quoted':
          updatePrecon(apiProjectID, true, true)
          break
        case 'Precon Resource':
          updatePrecon(apiProjectID, true, false)
          dispatch(Project.actions.fetchProjectTasks({projectID: apiProjectID, popCache: true}))
          dispatch(Project.actions.fetchTasksToAdd({projectID: apiProjectID, popCache: true}))
          break
        case 'Precon Dept':
          updatePrecon(apiProjectID, false, true)
          break
        case 'BC Integration Status':
          // do nothing for now - we don't want to overwrite the api message generated above
          break
        default:
          dispatch(Project.actions.setAPIMessages(message))
          break
      }
    }
  }
  //   ,
  //   [dispatch, updateExecutiveSummary, updateTradeSummary, updateProjectSummary, updatePrecon, authUserID]
  // )

  const messageHandler = useRef(parseMessage)
  useEffect(() => {
    console.log('**** messageHandler.current ****')
    console.log(authUserID)
    messageHandler.current = parseMessage
  })

  // Create the connection to SingalR Hub
  useEffect(() => {
    if (!connection) {
      console.log('*** Create HubConnection ***')
      const connect = new HubConnectionBuilder().withUrl(process.env.REACT_APP_CHATHUB).withAutomaticReconnect().build()
      dispatch(Project.actions.setHUBConnection(connect))
    }
  }, [connection, dispatch])

  // Connect to SingalR Hub
  useEffect(() => {
    let cancel = false
    console.log('*** HubConnection._connectionState:')
    console.log(connection && connection._connectionState)
    if (!isConnected && connection && connection._connectionState === 'Disconnected') {
      process.env.REACT_APP_DEBUG && console.log('*** Start HubConnection ***')
      connection
        .start()
        .then(() => {
          if (cancel) return
          setIsConnected(true)
          connection.on('ReceiveMessage', (userID, projectID, bidQuoteID, bidTradeID, message, calledByPayload) => {
            messageHandler.current(userID, projectID, bidQuoteID, bidTradeID, message, calledByPayload)
          })
        })
        .catch((error) => console.log(error))
    }
    // console.log(connection && connection._connectionState)
    return () => {
      cancel = true
    }
  }, [connection, isConnected])

  const wrapped = useMemo(
    () => ({
      hasReceivedUpdateTradeSummary,
      updateTradeSummaryMessageHandled,
      hasReceivedUpdatePreconResources,
      updatePreconResourcesMessageHandled,
      hasReceivedUpdatePreconDept,
      updatePreconDeptMessageHandled,
      hasReceivedUpdateExecutiveSummary,
      updateExecutiveSummaryMessageHandled,
      hasReceivedUpdateBidTradeGrid,
      updateBidTradeGridMessageHandled
    }),
    [hasReceivedUpdateBidTradeGrid, hasReceivedUpdateExecutiveSummary, hasReceivedUpdatePreconDept, hasReceivedUpdatePreconResources, hasReceivedUpdateTradeSummary, updateTradeSummaryMessageHandled]
  )

  return <APIMessagesContext.Provider value={wrapped}>{children}</APIMessagesContext.Provider>
}

export default APIMessagesContextProvider
